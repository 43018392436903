<style>
#page-container {
  height: 100vh;
  position: static;
  overflow-y: auto;
}
.diagonal-box {
  position: relative;
  background-image: linear-gradient(210deg, #2bd9eb 50%, #043561 50%);
}
</style>
<template>
  <div id="page-container" class="diagonal-box">
    <v-container v-if="organization" class="white mt-4">
      <h1 class="my-6 text-center">Request a New Appointment from {{ organization.name }}</h1>
      <p class="text-center">Use the form below to request an appointment at any of {{ organization.name }}'s locations.</p>
      <p v-if="showSuccessMessage" class="pa-6 text-center">
        Your request has been successfully received. Someone will reach out to the patient within 24 hours to schedule
        an initial visit. <br>
        You may now close this page. Click below if you would like to submit another request. <br>
        <v-btn class="mt-4" color="primary" @click="showSuccessMessage = false">New Request</v-btn>
      </p>
      <new-case-form v-else @created="handleCaseCreated" />
    </v-container>
  </div>
</template>
<script>
import NewCaseForm from '../components/Case/NewCaseForm'
export default {
  name: 'NewAppointmentRequest',
  components: { NewCaseForm },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    organization: null,
    showSuccessMessage: false
  }),
  created () {
    this.$store
      .dispatch('Organization/getOrganization', this.organizationId)
      .then(organization => {
        this.organization = organization
        this.$store.commit('Organization/setOrganization', organization)
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    handleCaseCreated () {
      this.showSuccessMessage = true
    }
  }
}
</script>
